import { Button } from "../../../lib/components/button";
import { Section } from "../../../lib/components/content/block";
import { TextareaInput } from "../../../lib/components/form/input";
import { SmallText, Title } from "../../../lib/components/typo";

export function NewFoundAndLostFragment() {
    return <Section>
        <Title>Perdu ou trouvé</Title>

        <SmallText className="m-2">Vous avez perdu ou trouvé un animal ? Ne tardez pas, profitez du réseau <span className="font-bold">Furry Jouney</span> pour vous aider!</SmallText>


        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type d'annonce</label>
                (O) Trouvé
            </div>
        </Section>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
                (O) Chien
            </div>
        </Section>


        <Section>
            <div className="border p-3 text-blue-700">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
                (O) Position courante
            </div>
        </Section>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des photos (masquez certains traits marquants)</label>
                (O) Cliquez ici
            </div>
        </Section>

        <Section>
            <TextareaInput label="Décrivez les circonstances et quelques traits manquants." />
        </Section>


        <SmallText className="m-2">Attention: Concervez un certain nombre de traits marquants secrets (une tâche sur une patte, la couleur du collier, un tâche dans l'oeil..) , 
            et demandez ces informations aux personnes qui vous contactent pour éviter les personnes mal intentionnées.</SmallText>

        <Button>Publier</Button>
    </Section>
}