import { Outlet } from "react-router-dom";
import { AsideFragment } from "../../layouts/fragments/aside";
import { Content, Page } from "../../layouts/frame";


export default function NewContentPage() {
  return (
    <Page>
      <Content>
        <Outlet />
      </Content>

      <AsideFragment>
      </AsideFragment>
    </Page>

  )
} 