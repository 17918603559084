import { Link } from "react-router-dom";
import { Button } from "../../../lib/components/button";
import { Section } from "../../../lib/components/content/block";
import { TextareaInput } from "../../../lib/components/form/input";
import { SmallText, Title } from "../../../lib/components/typo";

export function NewEventFragment() {
    return <Section>
        <Title>Nouvel évènement</Title>

        <SmallText className="m-2">Une promenade du dimanche dans votre quartier, un canicross dans votre ville ou une exposition dans votre région? Peut importe l'étendu de l'évènement, créez le maintenant!</SmallText>
        <SmallText className="m-2">Vous êtes un professionnel, veuillez consulter les <Link to="/" target="_blank" className="font-bold">conditions d'utilisation</Link>.</SmallText>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
                (O) Promenade
            </div>
        </Section>


        <Section>
            <div className="border p-3 text-blue-700">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
                (O) Position courante
            </div>
        </Section>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des photos</label>
                (O) Cliquez ici
            </div>
        </Section>

        <Section>
            <TextareaInput label="Commentaire" />
        </Section>

        <SmallText className="m-2">
          En créant cet évènement, vous acceptez <Link to={"/cgu#event"} className="text-blue-500 font-bold">les conditions d'utilisation</Link>, et notamment l'application stricte <Link to={"/cgu#event"} className="text-blue-500 font-bold">des règles de modération</Link>.
        </SmallText>
        <Button>Publier</Button>
    </Section>
}