import { NavLink, useOutletContext } from "react-router-dom";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { eventBus } from "../../../../layouts/frame";

interface ToolbarItem {
    href: string,
    icon: any,
    className?: string

}
export interface ToolbarProps {
    buttons: ToolbarItem[]
    // rightButtons: ToolbarItem[]
}

export function ToolBar(props: ToolbarProps) {
    // const {setScreenOverlayVisible } = useOutletContext<any>()

    const [buttons, setButtons] = useState<any>({
        b1: null,
        b2: null,
        b3: null,
        b4: null,
        b5: null,
    })

    useEffect(()=>{
        const bts = props.buttons.map(b => renderButton(b))
        setButtons({
            b1: bts[0],
            b2: bts[1],
            b3: renderButton({ href: '/new', className:"inline-flex flex-col items-center justify-center p-0  text-blue-600 z-10 group", icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" height="32"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path></svg>` }),
            b4: bts[2],
            b5: renderButton({ href: '/more', icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" height="24"><path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>` }),
        })
    }, [props])

    const renderButton  = useCallback((b: ToolbarItem)=>(
        <NavLink key={b.href} to={b.href} type="button" className={({isActive, isPending}) => classNames(b.className, 
            "inline-flex flex-col items-center justify-center p-4 hover:bg-gray-50 dark:hover:bg-gray-800 group", {'bg-blue-100 hover:bg-blue-100': isActive}, {'bg-red-500': isPending})}>
            <i dangerouslySetInnerHTML={{__html: b.icon}}/>
            <span className="sr-only">{b.href}</span>
        </NavLink>
    ), [])


    const openScreenOverlay = ()=>{
        console.log("open screen overlay")
        eventBus.next('screen-overlay:open')
    }

    return (
        <>
            <div className="xl:hidden w-full p-[28px]"></div>
            <div className="xl:hidden fixed bottom-0 z-50 w-full -translate-x-1/2 bg-white border-t border-gray-200 left-1/2 dark:bg-gray-700 dark:border-gray-600">
                <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
                    {buttons.b1}
                    {buttons.b2}
                    {buttons.b3}
                    {buttons.b4}
                    {buttons.b5}
                </div>
            </div>

        </>
    )
}