import { Fragment } from "react/jsx-runtime";
import { AsideFragment } from "../../layouts/fragments/aside";
import { ScreenOverlayAction, ScreenOverlayFragment } from "../../layouts/fragments/create";
import { BottomFilterFragment } from "../../layouts/fragments/filter";
import { SearchFragment } from "../../layouts/fragments/search";
import { Content, Page } from "../../layouts/frame";
import { Button } from "../../lib/components/button";
import { Section } from "../../lib/components/content/block";
import { TextareaInput } from "../../lib/components/form/input";
import { Grid, GridCard } from "../../lib/components/image/gridimage";
import { SubTitle, Title } from "../../lib/components/typo";
import { useEffect, useState } from "react";
import { GetExplore, GetPages } from "../../lib/api";
import { GetServices } from "../../lib/api/services";
import { CircleSkeleton, ImageSkeleton, LineSkeleton } from "../../lib/components/loader/skeleton";
import { NavLink } from "react-router-dom";




export function FeedCardSkeleton() {
  return <div className="animate-pulse mt-2 lg:max-w-[700px] border rounded items-center justify-center mx-auto p-2">
    <div className="flex flex-row">
      <div className="flex-none w-[65px]">
        <CircleSkeleton />
      </div>

      <div className="ml-4">
        <p>
          <LineSkeleton inline /> <span className="text-gray-500"> <LineSkeleton inline /></span>
        </p>

        <p>
          <div> <LineSkeleton /></div>
          <div>
            <ImageSkeleton className="lg:w-[600px] lg:h-[300px]" />
          </div>
        </p>
      </div>
    </div>

  </div>
}

function FeedCard(i: any) {
  return (
    <div className="mt-2 lg:max-w-[700px] border rounded items-center justify-center mx-auto p-2">
      <div className="flex flex-row">
        <div className="flex-none w-[65px]">
          <img className="w-[65px] h-[65px] rounded-full" src={i.account_image_url} />
        </div>

        <div className="ml-4">
          <p>
            <NavLink className="font-bold" to={`/profile/${i.account_id}`}>{i.account_display_name}</NavLink> <span className="text-gray-500">{i.city_name} · {i.created_at}</span>
          </p>

          <p>
            <div>{i.page_title}</div>
            <div>
              <img className="lg:w-[600px] lg:h-[300px]" src={i.page_media_url} />
            </div>
          </p>
        </div>
      </div>

      {/* <div className="flex flex-1 rounded-lg border ml-14 mt-3 cursor-pointer">
        <div className="flex justify-center items-center py-8 px-12 bg-gray-100 border-r">
          <i className="fas fa-newspaper text-3xl text-gray-500"></i>
        </div>

        <div className="flex-1 flex flex-col justify-center py-8 pr-16 pl-4">
          <p>Joshua Welford</p>
          <p className="text-gray-500">Joshua Welford</p>
          <p className="text-gray-500"><i className="fas fa-link"></i> welford.me</p>
        </div>
      </div> */}

      <div className="flex justify-around mt-4 pl-3">

        <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
          </svg>

          {i.stats_likes}
        </div>

        <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
          </svg>
          {i.stats_comments}
        </div>

        {/* <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
          </svg>
        </div> */}

        {/* <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
          </svg>
        </div> */}

        {/* <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
          </svg>

        </div> */}
      </div>
    </div>
  )
}

export default function PagesPage() {

  const [pages, setPages] = useState<any>()

  useEffect(() => {
    GetPages().then(setPages)
  }, [])

  if (!pages) {
    return (<Page>Chargement...</Page>)
  }


  return (
    <Page>
      <Content>
        <SearchFragment placeholder="Rechercher un page" filterElement={() => <Fragment>
          {pages.filters.map((f: any) => <div>
            {f.label}
            <input placeholder={f.type} />
          </div>)}
        </Fragment>} />

        {pages.sections[0].items.map((i:any) => FeedCard(i))}

        {/* {pages.sections.map((section: any) => <Section >
          <Title>{section.title}</Title>
          <Grid>
            {section.items.map((s: any) => <GridCard img={s.page_media_url} badge={s.page_title} href={'/pages/' + s.page_id} />)}
          </Grid>
        </Section>
        )
        } */}


      </Content>
      {/* <BottomFilterFragment>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Parcs
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg">
          Services
        </button>
        <button type="button" onClick={() => { }} className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Autour
        </button>
      </BottomFilterFragment> */}


      <AsideFragment>
      </AsideFragment>


    </Page>

  )
} 