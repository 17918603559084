import { Link } from "react-router-dom";
import { Button } from "../../../lib/components/button";
import { Section } from "../../../lib/components/content/block";
import { TextareaInput } from "../../../lib/components/form/input";
import { SmallText, Title } from "../../../lib/components/typo";

export function NewAdFragment() {
    return <Section>
        <Title>Nouvel annonce</Title>

        <SmallText className="m-2">Déposer les annonces des affaires que vous souhaites vendre ou acheter.</SmallText>
        <SmallText className="m-2">Vous êtes un professionnel, veuillez consulter les <Link to="/" target="_blank" className="font-bold">conditions d'utilisation</Link>.</SmallText>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type d'annonce</label>
                (O) Je vend
            </div>
        </Section>

        
        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
                (O) Accessoire pour chien
            </div>
        </Section>


        <Section>
            <div className="border p-3 text-blue-700">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
                (O) Position courante
            </div>
        </Section>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des photos</label>
                (O) Cliquez ici
            </div>
        </Section>

        <Section>
            <TextareaInput />
        </Section>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Prix</label>
                100 €
            </div>
        </Section>


        <Button>Publier</Button>
    </Section>
}