import { Button } from "../../../lib/components/button";
import { Section } from "../../../lib/components/content/block";
import { TextareaInput } from "../../../lib/components/form/input";
import { SmallText, Title } from "../../../lib/components/typo";

export function NewServiceFragment() {
    return <Section>
        <Title>Nouveau service</Title>

        <SmallText className="m-2">Vous proposez des services pour les animaux ? Créez votre annonce et faites en profiter la communauté!</SmallText>
        <SmallText className="m-2">Vous êtes un professionnel, completez soigneusement les informations légales, et faites certifier votre service par <span className="font-bold">Furry Journey</span>.</SmallText>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
                (O) Pet Sitter
            </div>
        </Section>


        <Section>
            <div className="border p-3 text-blue-700">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
                (O) Position courante
            </div>
        </Section>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des photos</label>
                (O) Cliquez ici
            </div>
        </Section>

        <Section>
            <TextareaInput />
        </Section>

        <Button>Publier</Button>
    </Section>
}