import { get } from "./fetch";

export interface FeedItem {
  account_account_type: string // "organization",
  account_display_name: string // "Les Petites Patounes",
  account_id: string // "2a59cec4-00da-4f93-8737-2a3938ee8057",
  account_image_url: string // "https://static.lespetitespatounes.com/avatars/2a59cec4-00da-4f93-8737-2a3938ee8057/pictures/4010501c-e469-11ee-85fc-4e7db3d247d3.webp",
  city_name: string // "Arcachon",
  country_name: string // "France",
  created_at: string // "2024-01-04T13:57:13.32798Z",
  department_name: string // "Gironde",
  description: string // "Nouvelle annonce de location",
  media_url: string // "https://static.lespetitespatounes.com/rooms/ce6e3af8-fc1f-413a-b9af-0f2fdd8e4bba/pictures/2f964e2e-ab09-11ee-8054-cac4905074bf",
  region_name: string // "Nouvelle-Aquitaine",
  stats_comments: string // 57,
  stats_likes: string // 85,
  title: string // "Maison de vacances à Arcachon"
}

export const GetExplore = () => get<{ items: FeedItem[] }>(`/explore`, { items: []})
export const GetMenu = () => get<any>(`/chrome/menu`, {})
export const GetGroups = () => get<any>(`/groups`, {})
export const GetGroup = ({id}:{id: string}) => get<any>(`/groups/${id}`, {})
