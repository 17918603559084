import { MapIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { AsideFragment } from "../../layouts/fragments/aside";
import { DynamicFragment, FragmentType } from "../../layouts/fragments/hydratror";
import { SearchFragment } from "../../layouts/fragments/search";
import { Content, Page } from "../../layouts/frame";
import { GetServiceCategoriesPets, GetServiceCategoriesTypes, GetServices, GetServicesFilters } from "../../lib/api/services";
import { Button } from "../../lib/components/button";
import { Section } from "../../lib/components/content/block";
import { Grid, GridSkeleton } from "../../lib/components/image/gridimage";
import { Title, TitleSkeleton } from "../../lib/components/typo";

export default function ServicesPages() {

  const [sections, setSections] = useState<any>()
  const [petCategories, setPetCategories] = useState<any>()
  const [typeCategories, setTypeCategories] = useState<any>()
  const [filters, setFilters] = useState<any>()

  useEffect(() => {
    GetServices().then(setSections)
    GetServiceCategoriesPets().then(setPetCategories)
    GetServiceCategoriesTypes().then(setTypeCategories)
    GetServicesFilters().then(setFilters)
  }, [])

  return (
    <Page>
      <Content>
        {filters && <SearchFragment placeholder={filters.placeholder} buttons={<>
          <MapIcon height={40} title="Vue carte" className="hover:bg-gray-200 rounded-full cursor-pointer p-2" />
        </>} filterElement={() => <Fragment>
          {filters.filters.map((f: any) => <div>
            {f.label}
            <input placeholder={f.type} />
          </div>)}
        </Fragment>} />}

        {!sections && Array.from({ length: 5 }, _ => <Section><TitleSkeleton /><GridSkeleton /></Section>)}

        {sections && sections.sections.map((section: any) => (
          <Section key={section.title} >
            <Title>{section.title}</Title>

            <Grid>
              {section.items.map((s: any) => {
                return DynamicFragment(FragmentType.CardElement, {
                  key: section.title + s.id,
                  img: s.media_url,
                  badge: s.type,
                  href: '/services/' + s.id,
                  line1: s.title,
                  line2: s.begin_at,
                  line3: s.location_department_name + ', ' + s.location_city_name
                })
              })}
            </Grid>
          </Section>
        ))
        }

        {petCategories && petCategories.sections.map((t: any) => <Section key={t.id}>
          <Title>{t.title}</Title>

          <div className=" bg-gray-200 p-3 rounded-lg">
            {t.items.map((s: any) => {
              return DynamicFragment(FragmentType.Badge, {
                className: "bg-white hover:bg-gray-100 block w-full text-center my-2 md:mx-2 md:px-3 md:w-fit",
                key: t.title + s.id,
                href: '/events?pet=' + s.id,
                label: s.title,
              })
            })}
          </div>
        </Section>
        )
        }

        {typeCategories && typeCategories.sections.map((t: any) => <Section key={t.id}>
          <Title>{t.title}</Title>

          <div className="bg-gray-200 p-3 rounded-lg">
            {t.items.map((s: any) => {
              return DynamicFragment(FragmentType.Badge, {
                className: "bg-white  hover:bg-gray-100 block w-full text-center my-2 md:mx-2 md:px-3 md:w-fit",
                key: t.title + s.id,
                href: '/services?type=' + s.id,
                label: s.title,
              })
            })}
          </div>
        </Section>
        )
        }

      </Content>


      <AsideFragment>
        <Section>
        <Button href="/new/services">Publier un service</Button>
        </Section>
      </AsideFragment>
    </Page>

  )
} 