import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
  } from "react-router-dom";
import ExplorePage from "./pages/explore";
import PlacesPage from "./pages/places";
import Frame, { ProtectedRoute } from "./layouts/frame";
import { ErrorPage } from "./layouts/error";
import { LoginPage } from "./pages/profile/login";
import { UserProfilePage } from "./pages/profile/profile";
import ExploreGroupsPage from "./pages/groups";
import { MoreMobilePage } from "./layouts/fragments/more";
import LodgingsPage from "./pages/lodgings";
import EventsPage from "./pages/events";
import ServicesPages from "./pages/services";
import { RegisterPage } from "./pages/profile/register";
import PlayerPage from "./pages/tv";
import { EventDetail } from "./pages/events/detail";
import GroupPage from "./pages/groups/group";
import PagesPage from "./pages/pages";
import GroupDiscussPage from "./pages/groups/fragments/discuss";
import GroupMediaPage from "./pages/groups/fragments/media";
import { LodgingDetailPage } from "./pages/lodgings/detail";
import AnimatedSwitch from "./lib/AnimatedSwitch";
import NewContentPage from "./pages/new";
import { NewPlaceFragment } from "./pages/new/fragments/new_place";
import { NewLodgingFragment } from "./pages/new/fragments/new_lodging";
import { NewServiceFragment } from "./pages/new/fragments/new_service";
import { NewEventFragment } from "./pages/new/fragments/new_event";
import { NewContentFragment } from "./pages/new/fragments/new";
import { NewGroupFragment } from "./pages/new/fragments/new_group";
import { NewAdFragment } from "./pages/new/fragments/new_ad";
import { NewPageFragment } from "./pages/new/fragments/new_page";
import { NewFoundAndLostFragment } from "./pages/new/fragments/new_fandl";
  
  export const router = createBrowserRouter([
    {
      path: "/",
      element:  <Frame/>,
      errorElement: <ErrorPage />,
      loader: async () => {
       console.log('load global translation..')
       return true
      },
      children: [
        {
          path: "",
          element: <ProtectedRoute/>,
          children:[
            {
              path: "",
              element: <Navigate to = "/explore" />,
            },
            {
              path: "explore",
              element: <ExplorePage/>,
            },
            {
              path: "places",
              element: <PlacesPage/>,
            },
            {
              path: "pages",
              element: <PagesPage/>,
            },
            {
              path: "new",
              element: <NewContentPage/>,
              children: [
                {
                  path: "",
                  element: <NewContentFragment/>,
                },
                {
                  path: "places",
                  element: <NewPlaceFragment/>,
                },
                {
                  path: "lodgings",
                  element: <NewLodgingFragment/>,
                },
                {
                  path: "services",
                  element: <NewServiceFragment/>,
                },
                {
                  path: "events",
                  element: <NewEventFragment/>,
                },
                {
                  path: "groups",
                  element: <NewGroupFragment/>,
                },
                {
                  path: "ad",
                  element: <NewAdFragment/>,
                },
                {
                  path: "pages",
                  element: <NewPageFragment/>,
                },
                {
                  path: "fandl",
                  element: <NewFoundAndLostFragment/>,
                },  

              ]
            },
            {
              path: "groups",
              children: [
                {
                  path: '',
                  element: <ExploreGroupsPage/>
                },
                {
                  path: ':id',
                  element: <GroupPage/>,
                  children: [
                    {
                      path: '',
                      element: <GroupDiscussPage/>
                    },
                    {
                      path: 'media',
                      element: <GroupMediaPage/>
                    },
                  ]
                },
                {
                  path: 'profile',
                  element: <UserProfilePage/>
                }
              ]
            },
            {
              path: "lodgings",
              children: [
                {
                  path: '',
                  element: <LodgingsPage/>
                },
                {
                  path: ':id',
                  element: <LodgingDetailPage/>
                },
              ]
            },
            {
              path: "events",
              children: [
                {
                  path: '',
                  element: <EventsPage/>,
                },
                {
                  path: ":id",
                  element: <EventDetail/>
                }
              ]
            },
            {
              path: "services",
              element: <ServicesPages/>,
            },
            {
              path: 'profile',
              element: <UserProfilePage/>
            },
            {
              path: "more",
              element: <MoreMobilePage/>,
            },
            {
              path: "tv",
              element: <PlayerPage/>,
            },
          ] 
        },
        
        {
          path: "login",
          element: <LoginPage/>,
        },
        
        {
          path: "register",
          element: <RegisterPage/>,
        },
      ]
    },
    
]);