import { MapIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { Feature } from "ol";
import { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { AsideFragment } from "../../layouts/fragments/aside";
import { MapContentFragment } from "../../layouts/fragments/map";
import { SearchFragment } from "../../layouts/fragments/search";
import { Content, Page } from "../../layouts/frame";
import { GetPlaces, GetPlacesFilters } from "../../lib/api";
import { Button, ToggleButton } from "../../lib/components/button";
import { Section } from "../../lib/components/content/block";
import { MapPopupCard } from "../../lib/components/image/gridimage";
import { FeatureType, FeatureTypeKey, NewPoint } from "../../lib/components/map/ol";
import { PlacesListFragment } from "./fragments/list";

export default function PlacesPage() {
  const { mainRef } = useOutletContext<any>()
  const [sections, setSections] = useState<any>()
  const [petCategories, setPetCategories] = useState<any>()
  const [typeCategories, setTypeCategories] = useState<any>()
  const [filters, setFilters] = useState<any>()
  const [isMapVisible, setMapVisible] = useState(true)
  const [features, setFeatures] = useState<Feature[]>([])

  useEffect(() => {
    GetPlaces().then((v: any) => {
      setSections(v)
      for (const section of v.sections) {
        setFeatures(section.items.map((i: any) => NewPoint(i.id, [i.location.address.lng, i.location.address.lat], { ...i, "selected": "", [`${FeatureTypeKey}`]: FeatureType.PLACE })))
      }
    })
    GetPlacesFilters().then(setFilters)

  }, [])


  if (!mainRef) {
    return <Page>Chargement..</Page>
  }

  return (
    <Page>
      <Content>
        {filters && <SearchFragment parent={mainRef} floating={false} placeholder={filters.placeholder} buttons={
          <ToggleButton ofElement={MapIcon} onElement={QueueListIcon} active={isMapVisible}
            height={40}
            title={isMapVisible ? "Vue liste" : "Vue carte"} className="hover:bg-gray-200 rounded-full cursor-pointer p-2"
            onClick={() => setMapVisible(v => !v)} />
        } filterElement={() => <Fragment>
          {filters.filters.map((f: any) => <div>
            {f.label}
            <input placeholder={f.type} />
          </div>)}
        </Fragment>} />}

        {mainRef && isMapVisible && sections ? (
          <MapContentFragment mainRef={mainRef} features={features} center={[sections.meta.center_lng, sections.meta.center_lat]} zoom={sections.meta.zoom}
            onSelect={(f: Feature, all: any) => {

              setFeatures(features.map(feat => {
                if (feat.getId() == f.getId()) {
                  feat.setProperties({ ...feat.getProperties(), 'selected': 'selected' });
                }
                return feat
              }))

              const p = f.getProperties()

              console.log('selected feature', p)
              return <MapPopupCard img={p.media_url} badge={p.type}
                line1={p.title}
                line2={p.location.address.street}
                line3={p.location.department.name + ', ' + p.location.city.name}
                href={"/places/" + p.id}
              />
            }} />
          // </div>
          // </div>
        ) : <PlacesListFragment filters={filters} sections={sections} categories={petCategories} />}


        {/* <BottomFilterFragment>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Parcs
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg">
          Services
        </button>
        <button type="button" onClick={() => refresh()} className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Autour
        </button>
      </BottomFilterFragment> */}
      </Content>
      <AsideFragment>
        <Section>
          <Button href="/new/places">Publier sur un lieu</Button>
        </Section>
      </AsideFragment>

    </Page>

  )
} 