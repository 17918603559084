import { Link } from "react-router-dom";
import { Button } from "../../../lib/components/button";
import { Section } from "../../../lib/components/content/block";
import { TextareaInput } from "../../../lib/components/form/input";
import { SmallText, Title } from "../../../lib/components/typo";

export function NewPlaceFragment(){
    return <Section>
        <Title>Nouveau lieu</Title>

        <SmallText className="m-2">Vous avez trouvé un lieu agréable ou utile ? Créez sa fiche et faites en profiter la communauté!</SmallText>
        <SmallText className="m-2">Déclarez tous les lieux amis des animaux (parc à chien, fontaines, plages, ...) et évaluer-le</SmallText>
        <SmallText className="m-2">Vous êtes un professionnel, veuillez consulter les <Link to="/" target="_blank" className="font-bold">conditions d'utilisation</Link>.</SmallText>

        <Section>
          <div className="border p-3 ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
            (O) Fontaine à eau
          </div>
        </Section>


        <Section>
          <div className="border p-3 text-blue-700">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
            (O) Position courante
          </div>
        </Section>


        <Section>
          <div className="border p-3 ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des photos</label>
            (O) Cliquez ici
          </div>
        </Section>

        <Section>
          <TextareaInput label="Commentaire"/>
        </Section>

        <Button>Créer</Button>
    </Section>
}