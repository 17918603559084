import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { Content, Page } from "../../layouts/frame";
import { Section } from "../../lib/components/content/block";
import { ControlledForm } from "../../lib/components/form";
import { PasswordInput, SubmitButton, TextInput } from "../../lib/components/form/input";
import { ProgressiveImage } from "../../lib/components/image";
import { SmallText, SubTitle, Title } from "../../lib/components/typo";
import { useUser } from "../../lib/hooks/useUser";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import SocialLoginPanel from "../../lib/components/panel/social_login";


export function LoginPage() {
  interface FormValues { email: string, password: string, token: string }

  const form = useForm<FormValues>()
  const { login, isLogged, getUser } = useUser()
  const navigate = useNavigate()


  const onLoginStart = useCallback(() => {
    alert('login start')
  }, [])


  const clients = {
    REACT_APP_FB_APP_ID: "azert",
    REACT_APP_GG_APP_ID: "619938608430-f774ug515jc90r1m88so2rgekrne23nj.apps.googleusercontent.com",
    REACT_APP_MICROSOFT_APP_ID: "azert",
    REACT_APP_LINKEDIN_APP_ID: "azert",
    REACT_APP_LINKEDIN_APP_SECRET: "azert",
    REACT_APP_TWITTER_V2_APP_KEY: "azert",
    REACT_APP_TIKTOK_CLIENT_KEY: "azert",
  }

  const handleSubmit = useCallback((data: FormValues) => {
    login(data).then(() => {
      return isLogged().then(() => {
        getUser().then((u) => {
          console.log('user already logged! redirect to callback else to /explore', u)
          navigate("/")
        }).catch(() => {
          console.error('user not logged !')
        })
      })
    })
  }, []);

  useEffect(() => {
    // isLogged().then(() => {
    //   console.log('user already logged! redirect to callback else to /explore')
    //   navigate("/")
    // }).catch(console.error)
  }, [])

  return <Page>
    <Content>

      <Section>
        <ControlledForm useForm={form} onSubmit={form.handleSubmit(handleSubmit)}>
          <div className={'flex flex-col justify-center items-center p-2 max-w-160 m-auto'}>
            <ProgressiveImage src="/img/furry_journey_transparent_white.png" className="h-44 bg-white" />

            <SubTitle>Connectez-vous pour continuer</SubTitle>
            <SocialLoginPanel clients={clients} onLoginStart={() => {
              console.log('login start..')
            }}
              onResolve={(e) => {
                console.log('resolve login..', e.provider, e.data)
              }} />
            
            <SubTitle>ou</SubTitle>

            <TextInput useForm={form} required={true} placeholder={"Email"} name={"email"} />
            <PasswordInput useForm={form} required={true} placeholder={"Mot de passe"} name={"password"} />

            <SubmitButton useForm={form} label={"Connexion"} />
            <SmallText>Vous avez un problème de connection? <NavLink to={"/account-recovery"} className={"text-[#1476dd]"}>Cliquez-ici</NavLink></SmallText>
            <div className="bg-default w-full h-[2px] m-6"></div>
            <SubTitle>Nouvel utilisateur?  <NavLink to={"/register"} className={"text-[#1476dd]"}>Créer mon compte</NavLink></SubTitle>
          </div>
        </ControlledForm>
      </Section>
    </Content>

    {/* <AsideFragment>
        <Section>
          
        </Section>
      </AsideFragment> */}
  </Page>
}