import React, { useCallback, useState } from 'react'
// import './app.css'
import { User } from './user' // component display user (see detail on /example directory)
import {
    LoginSocialGoogle,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    LoginSocialApple,
    LoginSocialTiktok,
    IResolveParams,
} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {
    FacebookLoginButton,
    GoogleLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    AppleLoginButton,
    XLoginButton,
    TikTokLoginButton,
} from 'react-social-login-buttons'

// import { ReactComponent as PinterestLogo } from './assets/pinterest.svg'
// import { ReactComponent as TiktokLogo } from './assets/tiktok.svg'

// REDIRECT URL must be same with URL where the (reactjs-social-login) components is locate
// MAKE SURE the (reactjs-social-login) components aren't unmounted or destroyed before the ask permission dialog closes
const REDIRECT_URI = window.location.href;

const SocialLoginPanel = ({clients, onLoginStart, onResolve}:{clients: any, onLoginStart: ()=>void, onResolve: ({ provider, data }: IResolveParams) => void}) => {
   
    return (
        <div className='flex flex-col w-full'>
            {/* <LoginSocialFacebook
                isOnlyGetToken
                appId={clients.REACT_APP_FB_APP_ID || ''}
                onLoginStart={onLoginStart}
                onResolve={onResolve}
                onReject={(err) => {
                    console.log(err)
                }}
            >
                <FacebookLoginButton text='Connexion avec Facebook' />
            </LoginSocialFacebook> */}

            <LoginSocialGoogle
                isOnlyGetToken
                client_id={clients.REACT_APP_GG_APP_ID || ''}
                onLoginStart={onLoginStart}
                onResolve={onResolve}
                onReject={(err) => {
                    console.log(err)
                }}
            >
                <GoogleLoginButton text='Connexion avec Google' />
            </LoginSocialGoogle>


            {/* <LoginSocialInstagram
                        isOnlyGetToken
                        client_id={app_id.REACT_APP_INSTAGRAM_APP_ID || ''}
                        client_secret={app_id.REACT_APP_INSTAGRAM_APP_SECRET || ''}
                        redirect_uri={REDIRECT_URI}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }: IResolveParams) => {
                            setProvider(provider)
                            setProfile(data)
                        }}
                        onReject={(err: any) => {
                            console.log(err)
                        }}
                    >
                        <InstagramLoginButton />
                    </LoginSocialInstagram> */}

            {/* <LoginSocialMicrosoft
                isOnlyGetToken
                client_id={app_id.REACT_APP_MICROSOFT_APP_ID || ''}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }: IResolveParams) => {
                    setProvider(provider)
                    setProfile(data)
                }}
                onReject={(err: any) => {
                    console.log(err)
                }}
            >
                <MicrosoftLoginButton text='Connexion avec Microsoft' />
            </LoginSocialMicrosoft>

            <LoginSocialLinkedin
                isOnlyGetToken
                client_id={app_id.REACT_APP_LINKEDIN_APP_ID || ''}
                client_secret={app_id.REACT_APP_LINKEDIN_APP_SECRET || ''}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }: IResolveParams) => {
                    setProvider(provider)
                    setProfile(data)
                }}
                onReject={(err: any) => {
                    console.log(err)
                }}
            >
                <LinkedInLoginButton text='Connexion avec LinkedIn' />
            </LoginSocialLinkedin> */}


            {/* <LoginSocialTwitter
                isOnlyGetToken
                client_id={clients.REACT_APP_TWITTER_V2_APP_KEY || ''}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onResolve={onResolve}
                onReject={(err: any) => {
                    console.log(err)
                }}
            >
                <XLoginButton text='Connexion avec X (Twitter)' />
            </LoginSocialTwitter>

            <LoginSocialTiktok
                client_key={clients.REACT_APP_TIKTOK_CLIENT_KEY}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onResolve={onResolve}
                onReject={(err) => {
                    console.log(err);
                }}
            >
                <TikTokLoginButton text='Connexion avec Tiktok' />
            </LoginSocialTiktok> */}
        </div>
    )
}

export default SocialLoginPanel