import { NavLink, Outlet } from "react-router-dom";
import { Button, ButtonOutline } from "../../lib/components/button";
import { Content, Page } from "../../layouts/frame";
import { Section } from "../../lib/components/content/block";
import { BottomFilterFragment, TopFilterFragment } from "../../layouts/fragments/filter";
import { AsideFragment } from "../../layouts/fragments/aside";
import { ScreenOverlayFragment } from "../../layouts/fragments/create";
import { Title } from "../../lib/components/typo";
import { TextareaInput, TextInput } from "../../lib/components/form/input";
import { SearchFragment } from "../../layouts/fragments/search";
import { Fragment } from "react/jsx-runtime";
import { Avatar, ProgressiveImage } from "../../lib/components/image";
import { useEffect, useState } from "react";
import { GetGroups } from "../../lib/api";
import { List, ListItem } from "../../lib/components/list";

export default function ExploreGroupsPage() {

  const [groups, setGroups] = useState<any>()

  useEffect(() => {
    GetGroups().then(setGroups)
  }, [])

  return (
    <Page>
      <Content>
        <SearchFragment placeholder="Rechercher un groupe" filterElement={() => <Fragment>

          FILTRES!
        </Fragment>} />

        {groups && groups.sections.map((g: any) => <Section>
          <Title>{g.title}</Title>
          <List>
            {g.items.map((i: any) => <ListItem key={i.id} href={"/groups/" + i.id} img={i.media_url} title={i.title} subtitle={i.subtitle} actions={i.actions} />)}
          </List>
        </Section>)}


      </Content>
      {/* MOBILE RENDERS */}

      {/* <BottomFilterFragment>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          New
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg">
          Popular
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Following
        </button>
      </BottomFilterFragment>
       */}



      <AsideFragment>
        <Section>
          <Button href="/new/groups">Créer un nouveau groupe</Button>
        </Section>
        {/* <div className="relative text-gray-300 w-80 p-5 bg-gray-300 rounded">
          <button type="submit" className="absolute ml-4 mt-3 mr-4">
            <svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" width="512px" height="512px">
              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"></path>
            </svg>
          </button>

          <input type="search" name="search" placeholder="Rechercher" className=" bg-dim-700 h-10 px-10 pr-5 w-full rounded-full text-sm focus:outline-none bg-purple-white shadow rounded border-0" />
        </div> */}

      </AsideFragment>
    </Page>

  )
} 