import { Link } from "react-router-dom";
import { Button } from "../../../lib/components/button";
import { Section } from "../../../lib/components/content/block";
import { SmallText, Title } from "../../../lib/components/typo";

export function NewGroupFragment() {
    return <Section>
        <Title>Nouveau groupe</Title>

        <SmallText className="m-2">Passionné(e) par les chiens, les chats, les NAC ou simplement échanger avec les personnes qui vous ressemble. Créer votre groupe maintenant.</SmallText>
        <SmallText className="m-2">Vous êtes un professionnel, veuillez consulter les <Link to="/" target="_blank" className="font-bold">conditions d'utilisation</Link>.</SmallText>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom du groupe</label>
                (O) Les fans de beagles
            </div>
        </Section>


        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mots clés</label>
                (O) Beagle , Chiens, Bon plan, Rencontres
            </div>
        </Section>


        <Section>
            <div className="border p-3 text-blue-700">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confidentialité</label>
                (O) Public
            </div>
        </Section>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des amis</label>
                (O) Facultatif
            </div>
        </Section>

        <SmallText className="m-2">
            En créant ce groupe, vous acceptez <Link to={"/cgu#group"} className="text-blue-500 font-bold">les conditions d'utilisation</Link>, et notamment l'application stricte <Link to={"/cgu#group"} className="text-blue-500 font-bold">des règles de modération</Link>.
        </SmallText>

        <Button>Créer mon groupe</Button>
    </Section>
}