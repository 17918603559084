import { MapIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { Feature } from "ol";
import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { AsideFragment } from "../../layouts/fragments/aside";
import { MapContentFragment } from "../../layouts/fragments/map";
import { SearchFragment } from "../../layouts/fragments/search";
import { Content, Page } from "../../layouts/frame";
import { GetLodgingCategoriesPets, GetLodgingCategoriesTypes, GetLodgingFilters, GetLodgings } from "../../lib/api";
import { Button, ToggleButton } from "../../lib/components/button";
import { Section } from "../../lib/components/content/block";
import { MapPopupCard } from "../../lib/components/image/gridimage";
import { FeatureType, FeatureTypeKey } from "../../lib/components/map/ol";
import { NewPoint } from "../../lib/components/map/ol/map";
import { LodgingListFragment } from "./fragments/list";



export default function LodgingsPage() {
  const mapRef = useRef<any>()
  const { mainRef } = useOutletContext<any>()
  const [sections, setSections] = useState<any>()
  const [petCategories, setPetCategories] = useState<any>()
  const [typeCategories, setTypeCategories] = useState<any>()
  const [filters, setFilters] = useState<any>()
  const [isMapVisible, setMapVisible] = useState(true)
  const [features, setFeatures] = useState<Feature[]>([])

  useEffect(() => {
    GetLodgings().then((v: any) => {
      setSections(v)
      for (const section of v.sections) {
        setFeatures(section.items.map((i: any) => NewPoint(i.id, [i.location.address.lng, i.location.address.lat], { ...i, "selected": "", [`${FeatureTypeKey}`]: FeatureType.LODGING })))
      }
    })
    GetLodgingCategoriesPets().then(setPetCategories)
    GetLodgingCategoriesTypes().then(setTypeCategories)
    GetLodgingFilters().then(setFilters)
  }, [])

  return (
    <Page>
      <Content>
        {filters && <SearchFragment parent={mainRef} floating={false} placeholder={filters.placeholder} buttons={
          <ToggleButton ofElement={MapIcon} onElement={QueueListIcon} active={isMapVisible}
            height={40}
            title={isMapVisible ? "Vue liste" : "Vue carte"} className="hover:bg-gray-200 rounded-full cursor-pointer p-2"
            onClick={() => setMapVisible(v => !v)} />
        } filterElement={() => <Fragment>
          {filters.filters.map((f: any) => <div>
            {f.label}
            <input placeholder={f.type} />
          </div>)}
        </Fragment>} />}


        {mainRef && isMapVisible && sections ? (
          // <div className="flex h-[calc(100vh-150px)]">
          //   <div className="hidden md:w-1/4 h-[calc(100vh-150px)] md:flex flex-col overflow-auto">
          //     {features.map((f: Feature) => (
          //       <div className={classNames("rounded ", { "bg-yellow-100": f.getProperties()['selected'] == 'selected' })}>{
          //         DynamicFragment(FragmentType.CardElement, {
          //           key: f.getId(),
          //           img: f.getProperties().media_url,
          //           badge: f.getProperties().type,
          //           href: '/lodgings/' + f.getId(),
          //           target: "_blank",
          //           line1: f.getProperties().title,
          //           // line2: f.getProperties().pets,
          //           line3: f.getProperties().location_department_name + ', ' + f.getProperties().location_city_name
          //         })}
          //       </div>
          //     ))
          //     }
          //   </div>
          //   <div className="w-full md:w-3/4" ref={mapRef}>
          <MapContentFragment mainRef={mainRef} features={features} center={[sections.meta.center_lng, sections.meta.center_lat]} zoom={sections.meta.zoom}
            onSelect={(f: Feature, all: any) => {

              setFeatures(features.map(feat => {
                if (feat.getId() == f.getId()) {
                  feat.setProperties({ ...feat.getProperties(), 'selected': 'selected' });
                }
                return feat
              }))

              const p = f.getProperties()

              console.log('selected feature', p)
              return <MapPopupCard img={p.media_url} badge={p.type}
                line1={p.title}
                line2={p.location.department.name + ', ' + p.location.city.name}
                line3={p.pets}
                href={"/lodgings/" + p.id}
              />
            }} />
          // </div>
          // </div>
        ) :
          <LodgingListFragment filters={filters} sections={sections} petCategories={petCategories} typeCategories={typeCategories} />}
      </Content>
      <AsideFragment>
        <Section>
          <Button href="/new/lodgings">Publier un hébergement</Button>
        </Section>
      </AsideFragment>

    </Page>
  )
} 