import { Link } from "react-router-dom";
import { Button } from "../../../lib/components/button";
import { Section } from "../../../lib/components/content/block";
import { TextareaInput } from "../../../lib/components/form/input";
import { SmallText, Title } from "../../../lib/components/typo";

export function NewPageFragment() {
    return <Section>
        <Title>Nouvelle page</Title>

        <SmallText className="m-2">Vous vous sentez l'âme d'un reporter ? <span className="text-blue-500">Les pages</span> sont faites pour vous !</SmallText>
        <SmallText className="m-2">Les pages vous permettent de créer du contenu de qualité, pérenne, et disponible, dont le but est de fournir des informations utiles fiables et vérifiées, des avis objectifs, 
            ou de faire découvrir vos expérences et vos bons plans. <br/> Pour garantie un contenu de qualité, toutes les pages sont soumises à modération par <span className="text-blue-500">Furry Journey</span>.</SmallText>
        <SmallText className="m-2">Une fois crée, vous pourrez continuer l'édition de votre contenu depuis votre profil, rubrique "Mes pages".</SmallText>
        <SmallText className="m-2">Vous êtes un professionnel, veuillez consulter les <Link to="/" target="_blank" className="font-bold">conditions d'utilisation</Link>.</SmallText>

        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
                (O) Reportage
            </div>
        </Section>
        
        <Section>
            <div className="border p-3 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mot clés</label>
                (O) Vacances , Cannes, Chien, 2024
            </div>
        </Section>


        <Section>
            <div className="border p-3 text-blue-700">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
                (O) Position courante
            </div>
        </Section>

        <Button>Etape suivante</Button>
    </Section>
}