'use client'

import { useCallback, useEffect, useRef, useState } from "react";

import { Geolocation } from "@capacitor/geolocation";
import { Collection, Feature, MapBrowserEvent, Overlay } from "ol";
import { FeatureLike } from "ol/Feature";
import { Select } from "ol/interaction";
import Map from 'ol/Map';
import View from 'ol/View';
import { Coordinate } from "ol/coordinate";
import { Point } from "ol/geom";
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from "ol/proj";
import { OSM } from "ol/source";
import { FeatureType, FeatureTypeKey, getMouseCoordinate } from "./ol";
import { GetGeolocation } from "./ol/geolocation";
import { NewClusterVectorLayer, NewCollection, NewFeaturesGeoJSON, NewGeolocationPoint, NewVectorSource } from "./ol/map";
import VectorSource from "ol/source/Vector";

interface Props {
  features?: any[],
  zoom?: number,
  center: Coordinate //lng, lat
  style: any,
  height?: any,
  width?: any,
  onClick?: (lngLat: Coordinate, map: Map) => void
  onSelectFeature?: (feature: Feature) => any
  onSelectFeatures?: (features: Feature[]) => void
  onRefetch?: (center: [number, number], zoom: number, bounds: [number, number, number, number]) => void
}


export const MapBox = ({ features, zoom, center, style, height, width, onClick, onSelectFeature, onSelectFeatures, onRefetch }: Props) => {
  const [geolocationWatchId, setGeolocationWatchId] = useState<string | undefined>()
  const [myMap, setMap] = useState<Map>()
  const [popup, setPopup] = useState<any>()
  const [selectedFeature, setSelectedFeature] = useState<Feature | undefined>()
  const [dataLayer, setDataLayer] = useState<{ features: any[] | Collection<any>, source: VectorSource, layer: any } | undefined>()
  const mapElement = useRef<any>()
  const popupElement = useRef<any>()
  const popupContentElement = useRef<any>()

  useEffect(() => {

    if (!mapElement.current || !center || myMap) {
      return
    }


    const overlay = new Overlay({
      element: popupElement.current,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    // initialize map
    const map = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        })
      ],
      view: new View({
        center: fromLonLat(center),
        zoom: zoom ? zoom : 5,
        minZoom: 0,
        maxZoom: 28,
      }),
      overlays: [overlay],
      controls: []
    })

    // initialize current user position 
    const currentLocation = NewGeolocationPoint()
    map.addLayer(currentLocation.layer)
    GetGeolocation((position, _) => {
      console.log('update position', position, currentLocation)
      if (position) {
        currentLocation.point.setGeometry(new Point(fromLonLat([position?.coords.longitude, position?.coords.latitude])))
      }
    }).then(setGeolocationWatchId)

    // main data feature
    const f = NewCollection([])
    const s = NewVectorSource(f)
    const l = NewClusterVectorLayer(s)
    map.addLayer(l)
    setDataLayer({ features: f, source: s, layer: l })




    // fit map to feature extent (with 100px of padding)
    // map.getView().fit(featuresLayer.getSource()!.getExtent(), {
    //   padding: [50, 50, 50, 50]
    // })

    // change mouse cursor when over marker
    map.on('pointermove', function (e: any) {
      const pixel = map.getEventPixel(e.originalEvent);
      const hit = map.hasFeatureAtPixel(pixel);
      // @ts-ignore
      map.getTarget()!.style.cursor = hit ? 'pointer' : '';
    });

    // Update the map's center based on user interaction
    const handleMapInteraction = (event: any) => {
      const newCenter = event.map.getView().getCenter();
      map.getView().setCenter(newCenter);
    };

    map.on('click', (evt: MapBrowserEvent<UIEvent>) => {
      setPopup(<></>)
      s.getFeatures().map(fs => fs.setProperties({ ...fs.getProperties(), 'selected': '' }));

      const coordinate = getMouseCoordinate(evt)
      const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature: FeatureLike) {
        return feature;
      });


      // not select features? then click on map
      // if (!feature || feature.length == 0) {
      //   if (onClick) {
      //     onClick(coordinate, map)
      //   }
      //   return;
      // }
      if (onSelectFeature && feature) {
        overlay.setPosition(evt.coordinate);
        // map.getView().setCenter(evt.coordinate)
        if (popupContentElement) {
          const feat = feature.get('features') ? feature.get('features').length == 1 ? feature.get('features')[0] : undefined : feature
          if (feat && feat.getProperties()[`${FeatureTypeKey}`] !== FeatureType.HERE) {
            setPopup(onSelectFeature(feat))
          }
        }
      }

      // if (onSelectFeatures && feature.get('features') && feature.get('features').length > 1) {
      //   onSelectFeatures(feature.get('features'))
      // }
    });

    setMap(() => map)
    return () => {
      if (geolocationWatchId) {
        Geolocation.clearWatch({ id: geolocationWatchId })
      }

      return map.setTarget(undefined)
    }
  }, []);

  useEffect(() => {
    if (dataLayer) {
      // console.log('[map] update datalayer ', dataLayer, ' with features:', features)
      // dataLayer.source.clear() //TODO clear only on refresh map, else partial data will be added
      if (features) {
        dataLayer.source.addFeatures(features)
      }
    }
  }, [dataLayer, features])


  const handleClick = useCallback(() => {
    // Handle map click...
    // onSelectFeature();
  }, [onSelectFeature]);


  // initialize map on first render - logic formerly put into componentDidMount
  useEffect(() => {


    // initMap.once('rendercomplete', function (e) {
    //   // var newZoom = initMap.getView().getZoom();
    //   console.log('rendercomplete : ', e);
    //   ready = true
    // });
    //
    // initMap.on('moveend', function (e: MapEvent) {
    //   // const coordinate = getMouseCoordinate(e)
    //   if (ready) {
    //     const bounds = initMap.getView().calculateExtent(initMap.getSize())
    //     const tBounds = [transformEPSG4326([bounds[0], bounds[3]]), transformEPSG4326([bounds[2], bounds[1]])]
    //     const center = transformEPSG4326(initMap.getView().getCenter()!)
    //     const zoom = initMap.getView().getZoom()!
    //     if (onRefetch) {
    //       // @ts-ignore
    //       onRefetch(center, zoom, tBounds)
    //     }
    //   }
    // });
    //
    // initMap.on('change', function (e) {
    //   var newZoom = initMap.getView().getZoom();
    //   console.log('change: ', newZoom);
    // });
    //
    // initMap.on('dblclick', function (e: MapBrowserEvent<UIEvent>) {
    //   const coordinate = getMouseCoordinate(e)
    //   console.log('dblclick:', coordinate);
    // });
    //
    // initMap.once('postrender', function (e) {
    //   // var newZoom = initMap.getView().getZoom();
    //   console.log('postrender : ', e);
    // });
    //
    //
    // initMap.on('propertychange', function (e) {
    //   console.log('propertychange:', e);
    // });
    //
    // initMap.on('singleclick', function (e: MapBrowserEvent<UIEvent>) {
    //   const coordinate = getMouseCoordinate(e)
    //   console.log('singleclick:', coordinate);
    // });
    //
    // 

    // fit map to feature extent (with 100px of padding)
    // initMap.getView().fit(initialShapeFeaturesLayer.getSource()!.getExtent(), {
    //   padding: [50, 50, 50, 50]
    // })


  }, [mapElement.current])


  return <div className="overflow-hidden flex" style={style}>
    <div ref={mapElement} className="h-full w-full bg-gray-500" />
    <div ref={popupElement} className="ol-popup" style={{ backgroundColor: '#fff' }}>
      <a href="#" id="popup-closer" className="ol-popup-closer"></a>
      <div ref={popupContentElement}>{popup}</div>
    </div>
  </div>
}
