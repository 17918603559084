import { Link } from "react-router-dom";
import { Section } from "../../../lib/components/content/block";


import { ArchiveBoxIcon, BanknotesIcon, CalendarIcon, ExclamationTriangleIcon, HomeModernIcon, MapPinIcon, MegaphoneIcon } from '@heroicons/react/24/outline';
import { SmallText, SubTitle } from "../../../lib/components/typo";

const CardButton = ({ title, icon, description, href }: { title: string, description: string, icon: any, href: string }) => {
    return <Link to={href} className="p-6 mb-3 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4 hover:bg-gray-200 cursor-pointer">
        <div className="shrink-0">
            {icon}
        </div>
        <div>
            <div className="text-xl font-medium text-black">{title}</div>
            <p className="text-slate-500">{description}</p>
        </div>
    </Link>
}
export function NewContentFragment() {
    return <Section>
        <SmallText className="text-center p-2">
            Publiez librement un tas de contenu! <br/>
            Faites découvrir des lieux dogs friendly, proposez votre logement vos services animalier, ou évaluer-en.<br/> 
            Organisez vos sorties avec d'autres propriétaires ! Sélectionnez la rubrique adaptée et publiez !
        </SmallText>
        <Section className="grid lg:grid-cols-2">
            <CardButton href="/new/places" icon={<MapPinIcon className="h-12 w-12" />} title="Nouveau lieu" description="Publier sur un restaurant, un parc à chien, ou tout autre emplacement dont vous souhaitez évaluer son accepation des animaux" />
            <CardButton href="/new/lodgings" icon={<HomeModernIcon className="h-12 w-12" />} title="Nouvel hébergement" description="Publier votre logement pour de la location, et offrez des vacances aussi aux animaux" />
            <CardButton href="/new/services" icon={<MegaphoneIcon className="h-12 w-12" />} title="Nouveau service" description="Professionnel ou non, proposez vos services animaliers (petsitter, éducateur, ...)" />
            <CardButton href="/new/events" icon={<CalendarIcon className="h-12 w-12" />} title="Nouvel évènement" description="Vous organisez un évènement local, régional ou national? grand public ou en petit comité? Faites-en la promotion" />
            <CardButton href="/new/ad" icon={<BanknotesIcon className="h-12 w-12" />} title="Nouvelle annonce" description="Achetez et vendez tout les accessoires pour animaux" />
            <CardButton href="/new/fandl" icon={<ExclamationTriangleIcon className="h-12 w-12" />} title="Perdu ou Trouvé" description="Communiquez efficacement la recherche ou la récupération d'un animal" />
        </Section>
    </Section>
}